<template>
  <div
    class="featured-product"
    :style="featuredProductStyle"
  >
    <div class="featured-product__title">{{ sectionJson.settings.title }}</div>
    <a-row
      type="flex"
      :gutter="{ xs: 8, sm: 16, md: 24 }"
    >
      <template v-for="item in sectionJson.blockOrder">
        <a-col
          v-if="productMap[item] && !sectionJson.blocks[item].disabled"
          :key="item"
          :xs="12"
          :sm="12"
          :md="8"
          :lg="8"
          :xl="6"
          class="featured-product__product-list__item"
        >
          <op-product
            :product-info="productMap[item]"
            :image-ratio="sectionJson.settings.imageRatio"
          ></op-product>
        </a-col>
      </template>
    </a-row>
  </div>
</template>

<script>
import { getProductListByIds } from '@/api'
export default {
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultProduct: {
        id: -1,
        salePrice: 0,
        originalPrice: 0,
        name: $t('home.default.productName'),
        picUrl: '',
      },
      productMap: {},
    }
  },

  computed: {
    featuredProductStyle() {
      return {
        paddingTop: this.sectionJson.settings.paddingTop + 'px',
        paddingBottom: this.sectionJson.settings.paddingBottom + 'px',
      }
    },
  },

  watch: {
    sectionJson: {
      deep: true,
      handler() {
        this.getDefaultProduct()
      },
      immediate: true,
    },
  },

  created() {},

  methods: {
    getDefaultProduct() {
      const realProductIds = []
      const realProductIdsMap = {} // key:真实商品id value:blockOrderItem
      const currentProductMap = {} // key:blockOrderItem value:product信息
      this.sectionJson.blockOrder.forEach((item, index) => {
        // productId小于0表示是假的商品
        if (this.sectionJson.blocks[item].settings.productId < 0) {
          currentProductMap[item] = {
            ...this.defaultProduct,
            id: this.sectionJson.blocks[item].settings.productId,
          }
        } else {
          if (this.sectionJson.blocks[item].settings.productId) {
            realProductIds.push(this.sectionJson.blocks[item].settings.productId)
            realProductIdsMap[this.sectionJson.blocks[item].settings.productId] = item
          }
        }
      })

      if (realProductIds.length === 0) {
        this.productMap = currentProductMap
        return
      }
      this.getProductListByIds(realProductIds, realProductIdsMap, currentProductMap)
    },
    async getProductListByIds(productIds, realProductIdsMap, currentProductMap = {}) {
      const { data } = await getProductListByIds({ goodsIds: productIds.sort((a, b) => a - b) }) // 因为后端缓存 所以将id list排序向后端传参
      const productList = data || []
      productList.forEach(item => {
        currentProductMap[realProductIdsMap[item.id]] = item
      })
      this.productMap = currentProductMap
    },
  },
}
</script>
<style lang="less" scoped>
.featured-product {
  width: 1440px;
  max-width: calc(100% - 80px);
  margin: 0 auto 24px;
  &__title {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 500;
    color: var(--color-title);
    text-align: center;
  }
  &__product-list__item {
    padding: 8px 0;
  }
}
@media (max-width: 1024px) {
  .featured-product {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 576px) {
  .featured-product {
    max-width: calc(100% - 32px);
  }
}
</style>
