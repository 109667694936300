var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide-show",class:[_vm.box_width]},[_c('op-swiper',{attrs:{"active-index":_vm.activeBlockIndex,"component-id":'op-swiper' + _vm.componentKey,"swiper-option":{
      speed: _vm.sectionJson.settings.changeSlidesSpeed,
      autoplay: !_vm.mode,
      initialSlide: _vm.mode ? 1 : 0,
    },"change-page-button":false}},[_vm._l(((_vm.sectionJson && _vm.sectionJson.blockOrder) || []),function(item){return [(!_vm.sectionJson.blocks[item].disabled)?_c('swiper-slide',{key:item,staticClass:"swiper-slide"},[_c('img',{directives:[{name:"compress",rawName:"v-compress",value:({ src: _vm.sectionJson.blocks[item].settings.imageUrl || _vm.defaultSlideImg }),expression:"{ src: sectionJson.blocks[item].settings.imageUrl || defaultSlideImg }"}],staticClass:"swiper-slide__image",class:[_vm.slide_height],style:({ opacity: _vm.sectionJson.blocks[item].settings.imageOverlayOpacity })}),(_vm.sectionJson.blocks[item].settings.isShowText)?_c('div',{staticClass:"swiper-slide__slide-content swiper-slide__slide-content-align--center",style:({
            background: _vm.sectionJson.blocks[item].settings.textBoxBgColor,
            color: _vm.sectionJson.blocks[item].settings.headingColor,
          })},[_c('div',{staticClass:"swiper-slide__slide-content__heading",style:({ 'font-size': _vm.sectionJson.blocks[item].settings.headingSize + 'px' })},[_vm._v(_vm._s(_vm.sectionJson.blocks[item].settings.heading))]),_c('div',{staticClass:"swiper-slide__slide-content__subheading",style:({ 'font-size': _vm.sectionJson.blocks[item].settings.headingSize + 'px' })},[_vm._v(_vm._s(_vm.sectionJson.blocks[item].settings.subheading))]),(_vm.sectionJson.blocks[item].settings.buttonLabel)?_c('op-button',{attrs:{"type":_vm.sectionJson.blocks[item].settings.outlineButtonStyle},on:{"click":function($event){return _vm.jumpPage(_vm.sectionJson.blocks[item].settings.link)}}},[_vm._v(_vm._s(_vm.sectionJson.blocks[item].settings.buttonLabel))]):_vm._e()],1):_vm._e()]):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }