<template>
  <div class="latest-product">
    <div class="latest-product__title">{{ sectionJson.settings.title }}</div>
    <op-product-list
      ref="opProductList"
      :product-list="productList"
      :loading="loading"
      :total="total"
      :is-show-pagination="false"
      :empty-description="$t('home.latest.noProduct')"
    ></op-product-list>
  </div>
</template>

<script>
import { getProductList } from '@/api'
export default {
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      productList: [],
      total: 0,
      loading: false,
    }
  },

  watch: {
    'sectionJson.settings.pageSize': {
      handler(val) {
        this.$refs.opProductList && this.$refs.opProductList.reloadData()
      },
    },
  },

  created() {
    this.getProductList()
  },

  methods: {
    async getProductList(pagination) {
      const defaultPagination = {
        size: this.sectionJson.settings.pageSize,
        start: 0,
      }
      try {
        this.loading = true
        const { data, totalRecords } = await getProductList({ ...pagination, ...defaultPagination })
        this.productList = data || []
        this.total = totalRecords
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.latest-product {
  width: 1440px;
  max-width: calc(100% - 80px);
  margin: 0 auto 24px;
  &__title {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 500;
    color: var(--color-title);
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .latest-product {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 576px) {
  .latest-product {
    max-width: calc(100% - 32px);
  }
}
</style>
