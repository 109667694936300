<template>
  <div
    class="featured-collection"
    :style="featuredCollectionStyle"
  >
    <div class="featured-collection__title">{{ sectionJson.settings.title }}</div>
    <a-row
      type="flex"
      :gutter="{ xs: 8, sm: 16, md: 24 }"
    >
      <a-col
        v-for="item in productList"
        :key="item.id"
        :xs="12"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="6"
        class="featured-collection__product-list__item"
      >
        <op-product
          :product-info="item"
          :image-ratio="sectionJson.settings.imageRatio"
        ></op-product>
      </a-col>
    </a-row>
    <div class="featured-collection__btn">
      <op-button
        v-if="viewMoreIsShow"
        type="outline"
        @click="viewMore"
      >{{ $t('home.collection.viewAll') }}</op-button>
    </div>
  </div>
</template>

<script>
import { getProductList } from '@/api'
export default {
  name: 'FeaturedGroup',
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      productList: [
        {
          id: -1,
          salePrice: 0,
          originalPrice: 0,
          name: $t('home.default.productName'),
          picUrl: '',
        },
        {
          id: -2,
          salePrice: 0,
          originalPrice: 0,
          name: $t('home.default.productName'),
          picUrl: '',
        },
        {
          id: -3,
          salePrice: 0,
          originalPrice: 0,
          name: $t('home.default.productName'),
          picUrl: '',
        },
        {
          id: -4,
          salePrice: 0,
          originalPrice: 0,
          name: $t('home.default.productName'),
          picUrl: '',
        },
      ],
      viewMoreIsShow: true,
    }
  },

  computed: {
    featuredCollectionStyle() {
      return {
        paddingTop: this.sectionJson.settings.paddingTop + 'px',
        paddingBottom: this.sectionJson.settings.paddingBottom + 'px',
      }
    },
  },

  watch: {
    sectionJson: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (val && val.settings && val.settings.collection) {
          this.getProductList()
        }
      },
    },
  },

  created() {},

  methods: {
    async getProductList() {
      // 如果collection小于0,表示不是真实商品集
      if (this.sectionJson.settings.collection < 0) {
        this.viewMoreIsShow = false
        return
      }
      const params = {
        start: 0,
        size: 10,
        categoryId: this.sectionJson.settings.collection,
      }
      const { data } = await getProductList(params)
      const currentProduct = data || []
      if (currentProduct.length > 4) {
        this.productList = currentProduct.slice(0, 4)
        this.viewMoreIsShow = true
      } else {
        this.viewMoreIsShow = false
        this.productList = currentProduct
      }
    },
    viewMore() {
      this.$router.push({
        path: `/collections/${this.sectionJson.settings.collection}`,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.featured-collection {
  width: 1440px;
  max-width: calc(100% - 80px);
  margin: 0 auto 24px;
  &__product-list__item {
    padding: 8px 0;
  }
  &__title {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 500;
    color: var(--color-title);
    text-align: center;
  }
  &__btn {
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .featured-collection {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 576px) {
  .featured-collection {
    max-width: calc(100% - 32px);
  }
}
</style>
