<template>
  <div class="mall-home">
    <template v-for="item in (pageTemplate && pageTemplate.pageSectionOrder) || []">
      <component
        :is="pageTemplate.pageSections[item].type"
        v-if="!pageTemplate.pageSections[item].disabled"
        :id="getSectionEleId(item)"
        :key="item"
        :mode="mode"
        :active-block-index="activeBlockIndex"
        :class="item === activeSectionId && 'active-section'"
        :component-key="item"
        :section-json="pageTemplate.pageSections[item]"
      ></component>
    </template>
  </div>
</template>

<script>
import SlideShow from './components/slide-show.vue'
import FeaturedCollection from './components/featured-group.vue'
import FeaturedProduct from './components/featured-product.vue'
import ImageBanner from './components/image-banner.vue'
import LatestProduct from './components/latest-product.vue'
import { getThemeTemplate, getFreeThemeTemplate } from '@/api'
import { initTheme } from '@/utils/theme'
import { PREVIEW_MODE } from '@/constants'
import { mapState } from 'vuex'
import { getSectionEleId } from '@/utils/index'
export default {
  name: 'Home',
  components: {
    SlideShow,
    FeaturedCollection,
    FeaturedProduct,
    ImageBanner,
    LatestProduct,
  },
  data() {
    return {
      mode: null,
      templateId: null,
      activeBlockIndex: 0,
    }
  },
  computed: {
    ...mapState(['layoutTemplate', 'activeSectionId', 'pageTemplate']),
  },

  created() {
    this.init()
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  destroyed() {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    getSectionEleId,
    init() {
      this.mode = this.$route.query.mode
      this.templateId = this.$route.query.themeId

      if (Number(this.mode) === PREVIEW_MODE.PREVIEW_MERCHANT && this.templateId) {
        this.getThemeTemplate()
      }
      if (Number(this.mode) === PREVIEW_MODE.PREVIEW_FREE && this.templateId) {
        this.getFreeThemeTemplate()
      }
    },
    // 接受商家后台postMessage消息
    receiveMessage(event) {
      // 点击菜单消息类型
      if (event.data.type === 'clickMenu') {
        const { activeBlockIndex, activeSectionId } = event.data || {}
        this.activeBlockIndex = activeBlockIndex
        this.scroll(activeSectionId)
        this.$store.commit('setActiveSectionId', activeSectionId)
      }
      // 更新配置数据消息类型
      if (event.data.type === 'update') {
        const { themeData, activeSectionId } = event.data || {}
        initTheme(themeData)
        this.$store.commit('setActiveSectionId', activeSectionId)
      }
    },
    // 获取商家主题数据
    async getThemeTemplate() {
      const { data } = await getThemeTemplate({ themeId: this.templateId })

      const themeData = JSON.parse((data || {}).content || '{}')
      initTheme(themeData)
    },
    // 获取免费主题数据
    async getFreeThemeTemplate() {
      const { data } = await getFreeThemeTemplate()
      const themeData = JSON.parse((data || {}).content || '{}')
      initTheme(themeData)
    },
    scroll(componentId) {
      const dom = document.querySelector(`#${getSectionEleId(componentId)}`)
      if (!dom) {
        return
      }

      dom.scrollIntoView({ behavior: 'smooth' })
    },
    postMessage() {
      window.parent.postMessage(
        {
          activePage: '',
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.mall-home {
  padding: 0 0;
}
</style>
<style></style>
