<template>
  <div
    class="image-banner"
    :class="[imageBannerWidth]"
  >
    <div
      v-show="sectionJson.settings.heading"
      class="image-banner__heading"
    >{{ sectionJson.settings.heading }}</div>
    <div
      v-show="sectionJson.settings.imageUrl"
      class="image-banner__container"
      @click="jumpPage(sectionJson.settings.linkUrl)"
    >
      <img
        v-compress="{ src: sectionJson.settings.imageUrl }"
        class="image-banner__container-image"
      />
      <div
        v-show="sectionJson.settings.text"
        class="image-banner__container-text"
        :class="[containerTextAlign]"
      >{{ sectionJson.settings.text }}</div>
    </div>
    <div
      v-show="!sectionJson.settings.imageUrl"
      class="image-banner__text"
    >{{ sectionJson.settings.text }}</div>
  </div>
</template>

<script>
import { jumpLink } from '@/utils/index'
export default {
  components: {},
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },

  computed: {
    imageBannerWidth() {
      return 'image-banner--' + this.sectionJson.settings.containerWidth
    },
    containerTextAlign() {
      return 'image-banner__container-text--' + this.sectionJson.settings.textAlign
    },
  },

  methods: {
    jumpPage(jumpInfo) {
      const { path, query } = jumpInfo || {}
      jumpLink(path, query)
    },
  },
}
</script>
<style lang="less" scoped>
.image-banner {
  width: 100%;
  &__heading {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 500;
    color: var(--color-title);
    text-align: center;
  }
  &__container {
    position: relative;
    width: 100%;
    &-image {
      display: block;
      width: 100%;
      object-fit: cover;
    }
    &-text {
      position: absolute;
      max-width: 50%;
      font-size: 22px;
      color: #fff;
      text-align: center;
      word-break: break-all;
      white-space: break-spaces;

      &--center {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
      &--left {
        top: 50%;
        left: 10%;
        transform: translate3d(0, -50%, 0);
      }
      &--right {
        top: 50%;
        right: 10%;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
  &__text {
    font-size: 22px;
    color: var(--color-text);
    text-align: center;
    word-break: break-all;
    white-space: break-spaces;
  }
}
.image-banner--padding {
  padding: 96px;
}
@media (max-width: 768px) {
  .image-banner--padding {
    padding: 32px;
  }
}
@media (max-width: 576px) {
  .image-banner--padding {
    padding: 0 16px;
  }
}
</style>
