<template>
  <div
    class="slide-show"
    :class="[box_width]"
  >
    <op-swiper
      :active-index="activeBlockIndex"
      :component-id="'op-swiper' + componentKey"
      :swiper-option="{
        speed: sectionJson.settings.changeSlidesSpeed,
        autoplay: !mode,
        initialSlide: mode ? 1 : 0,
      }"
      :change-page-button="false"
    >
      <template v-for="item in (sectionJson && sectionJson.blockOrder) || []">
        <swiper-slide
          v-if="!sectionJson.blocks[item].disabled"
          :key="item"
          class="swiper-slide"
        >
          <img
            v-compress="{ src: sectionJson.blocks[item].settings.imageUrl || defaultSlideImg }"
            :class="[slide_height]"
            class="swiper-slide__image"
            :style="{ opacity: sectionJson.blocks[item].settings.imageOverlayOpacity }"
          />
          <div
            v-if="sectionJson.blocks[item].settings.isShowText"
            class="swiper-slide__slide-content swiper-slide__slide-content-align--center"
            :style="{
              background: sectionJson.blocks[item].settings.textBoxBgColor,
              color: sectionJson.blocks[item].settings.headingColor,
            }"
          >
            <div
              class="swiper-slide__slide-content__heading"
              :style="{ 'font-size': sectionJson.blocks[item].settings.headingSize + 'px' }"
            >{{ sectionJson.blocks[item].settings.heading }}</div>
            <div
              :style="{ 'font-size': sectionJson.blocks[item].settings.headingSize + 'px' }"
              class="swiper-slide__slide-content__subheading"
            >{{ sectionJson.blocks[item].settings.subheading }}</div>
            <op-button
              v-if="sectionJson.blocks[item].settings.buttonLabel"
              :type="sectionJson.blocks[item].settings.outlineButtonStyle"
              @click="jumpPage(sectionJson.blocks[item].settings.link)"
            >{{ sectionJson.blocks[item].settings.buttonLabel }}</op-button>
          </div>
        </swiper-slide>
      </template>
    </op-swiper>
  </div>
</template>

<script>
import { jumpLink } from '@/utils/index'
export default {
  name: 'SlideShow',
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
    componentKey: {
      type: [String, Number],
      default: '',
    },
    mode: {
      type: [String, Number],
      default: '',
    },
    activeBlockIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      defaultSlideImg:
        'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/components/default-slide.png',
    }
  },

  computed: {
    slide_height() {
      return 'swiper-slide--' + this.sectionJson.settings.slideShowHeight
    },
    box_width() {
      return this.sectionJson.settings.slideShowWidth === 'padding' ? 'slide-show-box--padding' : ''
    },
  },
  created() {},

  methods: {
    jumpPage(jumpInfo) {
      const { path, query } = jumpInfo || {}
      jumpLink(path, query)
    },
  },
}
</script>
<style lang="less" scoped>
.slide-show {
  padding: 0 0;
  .swiper-slide {
    position: relative;
    .swiper-slide--small {
      height: 420px;
    }
    .swiper-slide--middle {
      height: 520px;
    }
    .swiper-slide--large {
      height: 680px;
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__slide-content {
      position: absolute;
      max-width: 70vw;
      padding: 32px 64px;
      text-align: center;
      word-break: break-all;
      background: #fff;
      &__subheading {
        margin: 16px 0;
      }
    }
    &__slide-content-align--center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.slide-show-box--padding {
  padding: 0 32px;
}

@media (max-width: 768px) {
  .slide-show {
    .swiper-slide {
      width: 100%;
      .swiper-slide--small {
        height: 40vw;
      }
      .swiper-slide--middle {
        height: 70vw;
      }
      .swiper-slide--large {
        height: 100vw;
      }
      &__image {
        display: block;
        width: 100%;
        object-fit: cover;
      }
      &__slide-content {
        width: 100%;
        max-width: 100vw;
        padding: 16px;
        transform: scale(0.8);
      }
      &__slide-content-align--center {
        transform: translate(-50%, -50%) scale(0.7);
      }
    }
  }
}
</style>
